import { cancelSubscriptionReq } from "../../../redux/subscription.slice"
import { Button, styled, Typography } from "@mui/material"
import Toaster from "../../../components/frontend/Toaster"
import { useDispatch, useSelector } from "react-redux"
import Moment from "react-moment"
import { Fragment } from "react"

const Div = styled("div")(() => ({}))
const Span = styled("span")(() => ({}))

const PlanDetail = () => {
	const dispatch = useDispatch()
	const { user } = useSelector(state => state.authSlice)

	const cancelSubscription = () => {
		dispatch(cancelSubscriptionReq(user?.currentplan?.subscriptionid))
	}

	return (
		<Fragment>
			{(user.appsumocode !== null && user.login_type === "appsumo") ?
				<Fragment>
					<Div className="font-medium font-ff-poppins">
						Lifetime Access to {user?.appsumouser?.type === "Pro" ? "Pro+" : "Solo"} Plan.
					</Div>
					{user?.appsumouser?.expired_at &&
						<Div>
							<Span className="font-medium font-ff-poppins">Renewal Date : </Span>
							<Span className="font-bold font-ff-lato text-deluge-600">[ <Moment format="DD MMM YYYY" withTitle>{user?.appsumouser?.expired_at}</Moment> ]</Span>
						</Div>
					}
				</Fragment> :
				<Fragment>
					<Div
						sx={{
							display: { sm: "flex" },
							alignItems: { sm: "flex-start" },
							justifyContent: { sm: "space-between" }
						}}
					>
						<Typography
							className="!font-ff-lato"
						>Subscription Details
						</Typography>
						{user?.currentplan?.status !== "canceled" &&
							<Button
								variant="contained"
								onClick={cancelSubscription}
								className="my-2 my-sm-0 fill-btn clr-dark"
							>Cancel Subscription
							</Button>
						}
					</Div>
					<Div>
						<Typography>
							<Span className="font-medium font-ff-poppins">Start Date : </Span>
							<Span className="font-bold font-ff-lato text-deluge-600">[ <Moment format="DD MMM YYYY" withTitle>{user?.currentplan?.startedAt}</Moment> ]</Span>
						</Typography>
						<Typography>
							<Span className="font-medium font-ff-poppins">End Date : </Span>
							<Span className="font-bold font-ff-lato text-deluge-600">[ <Moment format="DD MMM YYYY" withTitle>{user?.currentplan?.expiredAt}</Moment> ]</Span>
						</Typography>
					</Div>
					{user?.currentplan?.packagename === "SOLO" &&
						<Div>Your monthly word limit is 50,000.</Div>
					}
				</Fragment>
			}
			<Toaster />
		</Fragment>
	)
}

export default PlanDetail