import { Chip, Divider, Grid, IconButton, Button, styled, Typography } from "@mui/material"
import { createPaymentLinkReq, getAllSubscriptions } from "../../../redux/subscription.slice"
import { Link, useNavigate, useOutletContext } from "react-router-dom"
import Loader from "../../../components/frontend/TextLoader"
import Toaster from "../../../components/frontend/Toaster"
import { showDialog } from "../../../redux/dialog.slice"
import { useDispatch, useSelector } from "react-redux"
import { Fragment, useEffect } from "react"
import { hideLadingLoader } from "../../../helper"
import Icons from "../../../assets/icons"
import SoloProgress from "./SoloProgress"
import PlanDetail from "./PlanDetail"
import { Helmet } from "react-helmet"

const Div = styled("div")(() => ({}))

const Subscription = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [handleSidebar] = useOutletContext()
	const { user: auth } = useSelector(state => state.authSlice)
	const message = useSelector((state) => state.GetToastMessage)
	const { allSubscriptions } = useSelector(state => state.subscriptionSlice)

	let proPlan
	if (allSubscriptions?.data?.length) {
		const [plan] = allSubscriptions?.data?.filter(({ title, type }) => title === "PRO" && type === "monthly")
		proPlan = plan
	}

	useEffect(() => {
		hideLadingLoader();
		dispatch(getAllSubscriptions(auth?.currentplan?.price_id))

		// eslint-disable-next-line
	}, [message]);

	return (
		<Fragment>
			<Helmet>
				<title>Subscription</title>
				<meta property="og:title" content="Subscription" />
			</Helmet>
			{allSubscriptions?.loading && <Loader />}
			<Div
				className="border-b"
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					p: { xs: "10px", md: "5px 32px" },
				}}
			>
				<Div sx={{ gap: "8px", display: "flex", alignItems: "center" }}>
					<IconButton
						size="small"
						onClick={handleSidebar}
						className="sidebar-menu-btn"
						sx={{ display: { laptop: "none" } }}
					><Icons.FiMenu />
					</IconButton>
					<Typography
						component="h5"
						className="!font-ff-lato !text-[20px] !font-bold"
					>Subscription to&nbsp;
						{auth?.appsumocode === null ?
							auth?.currentplan?.packagename === "PRO" ? "Pro+" : "Solo" :
							auth?.appsumouser?.type === "Pro" ? "Pro+" : "Solo"
						}
						&nbsp;Plan
					</Typography>
				</Div>
				<Chip
					className="capitalize me-2 me-sm-0 mui-badge"
					sx={{ ".MuiChip-label": { padding: "3px 5px" } }}
					label={auth.appsumocode !== null ? "active" : auth?.currentplan?.status}
				/>
			</Div>
			<Div className="page-projects">
				<Div>
					<PlanDetail />
					{(auth?.currentplan?.packagename === "SOLO" ||
						auth?.appsumouser?.type === "Solo") &&
						<SoloProgress />
					}
					<Button
						to="/user/history"
						variant="contained"
						LinkComponent={Link}
						endIcon={<Icons.FiArrowRight />}
						className="fill-btn clr-primary !font-ff-lato !capitalize me-2 mt-3"
					>View Generation History
					</Button>
				</Div>

				{(auth?.appsumocode === null && auth?.currentplan?.packagename !== "PRO") &&
					<Div>
						<Divider sx={{ my: "10px", opacity: "1" }} />
						<Div sx={{ fontSize: "20px", fontWeight: "600", fontFamily: "Lato" }}>Upgrade</Div>
						<Div sx={{ mb: 1, fontFamily: "Lato" }}>Enjoy a limitless writing experience with our Pro+ plan!</Div>
						{proPlan && Object.keys(proPlan)?.length > 0 &&
							<Grid container spacing={1}>
								<Grid item xs={12} md={8}>
									<Div sx={{ p: 2, borderRadius: "6px", border: "1px solid #0000002d" }}>
										<Div
											className="text-gray-800"
											sx={{ mb: 1, fontSize: "20px", textTransform: "capitalize" }}
										>{proPlan?.title?.toLowerCase()}+
										</Div>
										<Typography
											component="h2"
											className="feature-color"
											sx={{ fontSize: "18px", fontWeight: "600", fontFamily: "Lato" }}
										>{proPlan?.words}
										</Typography>
										<Typography
											component="h4"
											className="text-gray-800"
											sx={{ mt: 2, mb: 3, fontSize: "24px", fontWeight: "600", fontFamily: "Lato" }}
										>${proPlan?.price} /mo
										</Typography>
										<Button
											variant="contained"
											className="mt-4 fill-btn clr-primary"
											endIcon={<Icons.Badge className="!text-base" />}
											onClick={() => { dispatch(createPaymentLinkReq({ planId: "660bf22575281f172b0245e7", navigate })) }}
										>Upgrade to Pro+
										</Button>
									</Div>
								</Grid>

								<Grid item xs={12} md={4}>
									{proPlan?.features &&
										JSON.parse(proPlan?.features)?.map((item, i) => (
											<Div key={i} sx={{ mb: "2px", fontSize: "14px" }}><Icons.FiCheck /> {item}</Div>
										))
									}
								</Grid>
							</Grid>
						}
					</Div>
				}

				{(auth?.appsumocode !== null) &&
					<Div sx={{ mt: 2, p: 2, borderRadius: "6px", border: "1px solid #0000002d" }}>
						<Div sx={{ fontSize: "14px", mb: "2px" }}><Icons.FiCheck /> Unlimited Projects</Div>
						<Div sx={{ fontSize: "14px", mb: "2px" }}><Icons.FiCheck /> AltaChat</Div>
						<Div sx={{ fontSize: "14px", mb: "2px" }}><Icons.FiCheck /> AltaCopy</Div>
						<Div sx={{ fontSize: "14px", mb: "2px" }}><Icons.FiCheck /> Brand Voice</Div>
						<Div sx={{ fontSize: "14px", mb: "2px" }}><Icons.FiCheck /> +90 Templates and all future ones</Div>
						<Div sx={{ fontSize: "14px", mb: "2px" }}><Icons.FiCheck /> +26 Languages and all future ones</Div>
					</Div>
				}

				<Div className="border rounded d-block p-3 mt-3">
					<Grid container spacing={2}>
						<Grid item xs={12} lg={8}>
							<Div className="text-[20px] !text-deluge-600">Enterprise</Div>
							<Div>Need a more customized solution for your organization?</Div>
						</Grid>
						<Grid item xs={12} lg={4}>
							<Button
								variant="contained"
								endIcon={<Icons.FiArrowRight />}
								onClick={() => { dispatch(showDialog("SalesDialog")) }}
								className="fill-btn my-4 clr-primary !font-ff-lato !capitalize me-2"
							>Contact Us
							</Button>
						</Grid>
					</Grid>
				</Div>
			</Div>
			<Toaster />
		</Fragment>
	)
}

export default Subscription;